import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Tag} from "antd";
import React from "react";

export const RequestActivationHelper = {
    /* Vista para clientes */
    getAttentionRequiredForClients: (requestActivationStatusId: string) => {
        if(['CREATED', 'APPROVED_BY_BUSINESS_CHAIN'].includes(requestActivationStatusId)) {
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="purple">
                    Cargar cotización
                </Tag>
            );
        }

        if(['PURCHASE_ORDER_TO_REVIEW'].includes(requestActivationStatusId)) {
            return (
                <Tag icon={<ExclamationCircleOutlined />} color="processing">
                    Validar orden de compra
                </Tag>
            );
        }

        return (
            <Tag color="default">
                Ninguna
            </Tag>
        );
    }
}
