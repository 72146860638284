import React, {useEffect, useState} from 'react';
import {Contact} from "../../../../../models/Contact";
import {Alert, Button, Card, Col, Divider, message, Modal, Row, Space} from "antd";
import {RequestActivation} from "../../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import BcRequestActivationDetailModalSkeleton from "./BcRequestActivationDetailModalSkeleton";
import BcLeftSidebar from "./BcLeftSidebar";
import {RequestActivationService} from "../../../../../services/RequestActivationService";
import BcGeneralDetailTab from "./BcGeneralDetailTab";
import BcScheduleTab from "./BcScheduleTab";
import BcNoteTab from "./BcNoteTab";
import {RequestActivationStatus} from "../../../../../models/RequestActivationStatus";
import {
    CalendarOutlined,
    CreditCardOutlined, ExclamationCircleOutlined,
    FileOutlined
} from "@ant-design/icons";

import './BcRequestActivationDetailModal.scss';
import RefusedRequestModal from "../RefusedRequestModal";

interface RequestActivationDetailModalProps {
    isOpen: boolean;
    handleModalCancel: () => void;
    selectedRequestActivation: RequestActivation;
    contacts: Contact[];
    requestActivationStatuses: RequestActivationStatus[];
    reloadGrid: (currentPage?: number) => void;
}

function BcRequestActivationDetailModal({ selectedRequestActivation, isOpen, handleModalCancel, contacts, requestActivationStatuses, reloadGrid }: RequestActivationDetailModalProps) {
    const [messageApi, contextHolder] = message.useMessage();
    const [modal, contextModalHolder] = Modal.useModal();
    const themeConfig = useThemeLayoutConfig();
    const staticHeight = '65vh';
    const [activeTabKey, setActiveTabKey] = useState<string>('general_details');
    const [requestActivation, setRequestActivation] = useState<RequestActivation>();
    const [loading, setLoading] = useState(false);

    const [isRefusedByBusinessChainStatusModalOpen, setIsRefusedByBusinessChainStatusModalOpen] = useState(false);

    useEffect(() => {
        setRequestActivation(undefined);

        if(isOpen) {
            setActiveTabKey('general_details');
            fetchData();
        }
    }, [isOpen])

    const fetchData = async (reloadGridValue: boolean = false) => {
        setLoading(true);
        const [
            requestActivationResponse
        ] = await Promise.all([
            RequestActivationService.findById(selectedRequestActivation.id),
        ]);

        if(requestActivationResponse.success) {
            setRequestActivation(requestActivationResponse.data);
            if(reloadGridValue) {
                reloadGrid();
            }
        }else {
            if(!requestActivationResponse.success) {
                const error = requestActivationResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar cancelar la solicitud de activación, por favor inténtalo nuevamente.', 3.5);
            }
        }
        setLoading(false);
    }

    const tabList = [
        { key: 'general_details', label: 'Detalles generales', icon: <CreditCardOutlined /> },
        { key: 'schedule', label: 'Programación', icon: <CalendarOutlined /> },
        { key: 'notes', label: 'Notas de solicitud', icon: <FileOutlined /> },
    ];

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const tabsContent: Record<string, React.ReactNode> = {
        general_details: <BcGeneralDetailTab requestActivation={requestActivation!} />,
        schedule: <BcScheduleTab requestActivation={requestActivation!} />,
        notes: <BcNoteTab requestActivation={requestActivation!} />,
    };

    const handleApprove = async () => {
        if(requestActivation) {
            modal.confirm({
                title: 'Confirmación',
                icon: <ExclamationCircleOutlined />,
                content: <span>¿Estás seguro que deseas validar la solicitud con N° <b>{requestActivation.id}</b>?</span>,
                okText: 'SI',
                cancelText: 'NO',
                onOk: async () => {
                    const response = await RequestActivationService.updateStatusToApprovedBusinessChain(requestActivation.id);

                    if(response.success) {
                        await fetchData(true);

                        messageApi.success(<span>Se validó la solicitud con N° <b>{requestActivation.id} de manera satisfactoria.</b></span>, 3.5);
                    }else {
                        messageApi.error(response.data.message as string || 'Hubo un error al intentar validar la solicitud, por favor inténtalo nuevamente.', 3.5);
                    }
                }
            });
        }
    }

    const closeRefusedByBusinessChainStatusModal = () => {
        setIsRefusedByBusinessChainStatusModalOpen(false);
    }

    const handleReject = async () => {
        if(requestActivation && !loading) {
            setIsRefusedByBusinessChainStatusModalOpen(true);
        }
    }

    return (
        <>
            { contextHolder }
            { contextModalHolder }

            <Modal
                title={<span>Solicitud N°: <span style={{ color: themeConfig.token?.colorPrimary }} >{selectedRequestActivation.id}</span></span>}
                open={isOpen}
                onCancel={handleModalCancel}
                okText="Guardar"
                maskClosable={false}
                destroyOnClose
                width="90%"
                footer={null}
            >
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }}/>

                {
                    (!(!requestActivation || loading) && requestActivation.requestActivationStatusId === 'VALIDATION_REQUIRED')  && (
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Alert
                                message="La solicitud requiere de validación por parte de la cadena."
                                type="info"
                                showIcon
                                className="alert-custom-alert"
                                action={(
                                    <Space direction="horizontal">
                                        <Button size="small" type="primary" onClick={handleApprove}>
                                            Validar
                                        </Button>
                                        <Button size="small" type="primary" danger onClick={handleReject}>
                                            Rechazar
                                        </Button>
                                    </Space>
                                )}
                            />
                        </Col>
                    )
                }

                {
                    (!requestActivation || loading) && (
                        <BcRequestActivationDetailModalSkeleton/>
                    )
                }

                {
                    !(!requestActivation || loading) && (
                        <Row gutter={24}>
                            <Col xs={24} md={24} lg={6} xl={6}  xxl={6} style={{ marginBottom: '15px' }}>
                                <Card style={{ height: staticHeight, overflowX: "auto" }}>
                                    <BcLeftSidebar
                                        requestActivation={requestActivation}
                                        contacts={contacts}
                                    />
                                </Card>
                            </Col>

                            <Col xs={24} md={24} lg={18} xl={18} xxl={18} style={{ marginBottom: '15px' }}>
                                <Card
                                    style={{
                                        height: staticHeight,
                                        overflowX: "auto",
                                    }}
                                    tabList={tabList}
                                    tabProps={{
                                        size: 'middle',
                                    }}
                                    activeTabKey={activeTabKey}
                                    onTabChange={onTabChange}
                                    className={ activeTabKey === 'notes' ? 'bc-request-activation-detail-modal-card-body-notes-padding' : '' }
                                >
                                    {requestActivation && tabsContent[activeTabKey]}
                                </Card>
                            </Col>
                        </Row>
                    )
                }

                {
                    requestActivation && (
                        <RefusedRequestModal
                            isOpen={isRefusedByBusinessChainStatusModalOpen}
                            handleModalCancel={closeRefusedByBusinessChainStatusModal}
                            selectedRequestActivation={requestActivation}
                            fetchData={fetchData}
                        />
                    )
                }
            </Modal>
        </>
    );
}

export default BcRequestActivationDetailModal;
