import React, {useEffect, useState} from 'react';
import Layout from "../../../components/Layout/Layout";
import {
    EyeOutlined,
    FormOutlined,
    MoreOutlined
} from "@ant-design/icons";
import {Button, Card, Checkbox, Col, Dropdown, Input, MenuProps, message, Modal, Row, Table, TableProps} from "antd";
import {SearchProps} from "antd/es/input";
import {RequestActivation} from "../../../models/RequestActivation";
import Column from "antd/es/table/Column";
import {TableParams} from "../../../models/TableParams";
import {PAGE_SIZE} from "../../../config/Constants";
import {RequestActivationService} from "../../../services/RequestActivationService";
import {PaginationData} from "../../../models/PaginationData";
import moment from "moment/moment";
import {RequestActivationStatus} from "../../../models/RequestActivationStatus";
import {Contact} from "../../../models/Contact";
import {ContactService} from "../../../services/ContactService";
import BcRequestActivationDetailModal from "./components/BcRequestActivationDetailModal/BcRequestActivationDetailModal";
import {CheckboxChangeEvent} from "antd/es/checkbox";

import './BusinessChainRequestHistory.scss';

const { Search } = Input;

const STORAGE_KEY = "showOnlyValidationRequiredBCR";

function ActivationHistory() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<RequestActivation[]>([]);
    const [requestActivationStatuses, setRequestActivationStatuses] = useState<RequestActivationStatus[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });

    const [term, setTerm] = useState('');
    const [showOnlyValidationRequired, setShowOnlyValidationRequired] = useState<boolean>(() => {
        const storedValue = localStorage.getItem(STORAGE_KEY);
        return storedValue !== null ? JSON.parse(storedValue) : true;
    });
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<RequestActivation | undefined>(undefined);
    const [contacts, setContacts] = useState<Contact[]>([]);

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, showOnlyValidationRequired, term, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        const [
            requestActivationStatusResponse,
            contactResponse
        ] = await Promise.all([
            RequestActivationService.getAllRequestActivationStatuses(),
            ContactService.getByCurrentCompany(),
            fetchData()
        ]);

        if(requestActivationStatusResponse.success && contactResponse.success) {
            setRequestActivationStatuses(requestActivationStatusResponse.data);
            setContacts(contactResponse.data);
            setLoading(false);
        }else {
            if(!contactResponse.success) {
                const error = contactResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los contactos, por favor inténtalo nuevamente.', 3.5);
            }

            if(!requestActivationStatusResponse.success) {
                const error = requestActivationStatusResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los estados de las activaciones, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);


        const datasourceResponse = await RequestActivationService.getAllByCurrentUserAndSelectedBusinessChain({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: term || undefined,
            requestActivationStatusIds: showOnlyValidationRequired ? [ 'VALIDATION_REQUIRED' ] : undefined
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data as PaginationData<RequestActivation>;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<RequestActivation>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: RequestActivation): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver solicitud</span>,
                onClick: () => { showDetailModalOpen(row); }
            },
        ];
    }

    const showDetailModalOpen = (row: RequestActivation) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        const newValue = e.target.checked;
        setShowOnlyValidationRequired(newValue);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(newValue));
    };
    return (
        <Layout breadcrumb={[
            { title: <span><FormOutlined/> Activaciones</span> },
            { title: 'Solicitudes' }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >

                <div className="filter-container">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search placeholder="N° de solicitud, marca o cliente" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container-ls">
                            <div style={{ display: 'flex', alignItems: 'center' }} >
                                <Checkbox
                                    checked={showOnlyValidationRequired}
                                    disabled={tableLoading}
                                    onChange={handleCheckboxChange}
                                >
                                    Mostrar solo solicitudes que requieran validación
                                </Checkbox>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <RequestActivation>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ x: 'auto' }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column title="N° Solicitud" dataIndex="id" key="id"/>

                    <Column title="Marca" key="brand" render={(row: RequestActivation) => (
                        <>{row.brand?.name}</>
                    )} sorter={true}/>

                    <Column title="Cliente" key="company" render={(row: RequestActivation) => (
                        <>{row.company?.name}</>
                    )} sorter={true}/>

                    <Column title="Fecha de solicitud" key="createdAt" render={(row: RequestActivation) => (
                        <span title={moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{moment(row.createdAt).format('DD/MM/YYYY hh:mm A')}</span>
                    )} sorter={true} defaultSortOrder="descend"/>

                    <Column title="Estado" key="status" render={(row: RequestActivation) => (
                        <span
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                                <span
                                    style={{
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        backgroundColor: row.requestActivationStatus?.alternativeColor,
                                        display: 'inline-block',
                                        marginRight: 8,
                                    }}
                                ></span>
                            {row.requestActivationStatus?.alternativeName}
                        </span>
                    )} />

                    <Column width={120} align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            {
                selectedRow && (
                    <BcRequestActivationDetailModal
                        isOpen={isDetailModalOpen}
                        handleModalCancel={closeDetailModalOpen}
                        selectedRequestActivation={selectedRow}
                        contacts={contacts}
                        requestActivationStatuses={requestActivationStatuses}
                        reloadGrid={fetchData}
                    />
                )
            }
        </Layout>
    );
}

export default ActivationHistory;
