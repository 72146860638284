import React from 'react';
import {Navigate, useLocation, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Unauthorized from '../../scenes/Unauthorized/Unauthorized';
import { hasRoutePermissions } from '../../utils/AuthUtils';
import {MenuType} from "../../config/Config";
import {User} from "../../models/User";
import {FunctionsHelper} from "../../utils/FunctionsHelper";

type PrivateRouteProps = {
  outlet: JSX.Element;
  permissionsRequired?: string | string[];
  type?: MenuType;
  requiredDynamicValidation?: boolean;
};

function PrivateRoute({ outlet, permissionsRequired = [], type = MenuType.STANDARD_MENU, requiredDynamicValidation = true }: PrivateRouteProps) {
  const user: User = useSelector((state: any) => state.auth);
  const params = useParams();
  const location = useLocation();
  const currentPath = location.pathname;

  if (user?.id !== 0 && hasRoutePermissions(permissionsRequired, user)) {
    if (MenuType.IFRAME_VIEWER === type) {
      const { key } = params;

      const availableMenuData = FunctionsHelper.flattenMenuArray(user.allowedMenus).find((menu) => {
        return menu.code === key && menu.menuTypeId === type;
      });

      if (!availableMenuData) {
        return <Unauthorized />;
      }
    }

    if (MenuType.STANDARD_MENU === type && requiredDynamicValidation) {
      const availableMenuData = FunctionsHelper.flattenMenuArray(user.allowedMenus).filter((menu) => {
        return menu.menuTypeId === MenuType.STANDARD_MENU
      }).map((menu) => {
        return (menu.redirectLink || '').toLowerCase()
      });

      if (!availableMenuData.includes(currentPath.toLowerCase())) {
        return <Unauthorized />;
      }
    }

    return outlet;
  } else if (
      user?.id !== 0 &&
      !hasRoutePermissions(permissionsRequired, user)
  ) {
    return <Unauthorized />;
  }

  return <Navigate to="/login" />;
}

export default PrivateRoute;
