import React, { useState, useMemo } from 'react';
import { Modal, Calendar, Badge } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

import './DateRangeModal.scss';
import {FunctionsHelper} from "../../utils/FunctionsHelper";

interface DateRangeModalProps {
    open: boolean;
    onClose: () => void;
    dates: string[];
    showHeader?: boolean;
}

interface MonthYear {
    month: number;
    year: number;
    label: string;
    count: number;
}

const DateRangeModal: React.FC<DateRangeModalProps> = ({ open, onClose, dates, showHeader = false }) => {
    const monthYears = useMemo(() => {
        if (!dates.length) return [];

        const monthCounts = new Map<string, number>();
        dates.forEach(date => {
            const d = dayjs(date);
            const key = `${d.month()}-${d.year()}`;
            monthCounts.set(key, (monthCounts.get(key) || 0) + 1);
        });

        const uniqueMonthYears = Array.from(monthCounts.entries()).map(([key, count]) => {
            const [month, year] = key.split('-').map(Number);
            return {
                month,
                year,
                label: FunctionsHelper.capitalizeFirstLetter(dayjs().month(month).year(year).format('MMMM - YYYY')),
                count
            };
        });

        return uniqueMonthYears.sort((a, b) => {
            if (a.year !== b.year) return a.year - b.year;
            return a.month - b.month;
        });
    }, [dates]);

    const [selectedMonthYear, setSelectedMonthYear] = useState<MonthYear | null>(null);

    React.useEffect(() => {
        if (monthYears.length > 0 && !selectedMonthYear) {
            setSelectedMonthYear(monthYears[0]);
        }
    }, [monthYears, selectedMonthYear]);

    const dateSet = new Set(dates.map(date => dayjs(date).format('YYYY-MM-DD')));

    const dateCellRender = (date: Dayjs) => {
        const dateString = date.format('YYYY-MM-DD');
        if (dateSet.has(dateString)) {
            return (
                <div style={{
                    backgroundColor: '#1677ff',
                    color: 'white',
                    width: '20px',
                    height: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                    margin: '0 auto',
                    fontSize: '12px'
                }}>
                    {date.date()}
                </div>
            );
        }
        return date.date();
    };

    return (
        <Modal
            open={open}
            onCancel={onClose}
            footer={null}
            width={600}
            title="Fechas seleccionadas"
        >
            <div className="custom-date-sequence-modal-content">
                <div className="custom-date-sequence-month-tabs">
                    {monthYears.map((my) => (
                        <div
                            key={`${my.month}-${my.year}`}
                            className={`custom-date-sequence-month-tab ${
                                selectedMonthYear?.month === my.month &&
                                selectedMonthYear?.year === my.year ? 'active' : ''
                            }`}
                            onClick={() => setSelectedMonthYear(my)}
                        >
                            <span>{my.label}</span>
                            <Badge
                                count={my.count}
                                style={{
                                    backgroundColor: selectedMonthYear?.month === my.month &&
                                    selectedMonthYear?.year === my.year ? '#1677ff' : '#999',
                                    marginLeft: '8px'
                                }}
                            />
                        </div>
                    ))}
                </div>
                <div className="custom-date-sequence-calendar-container">
                    {selectedMonthYear && (
                        <>
                            {
                                showHeader && (
                                    <div className="custom-date-sequence-calendar-header">
                                        {selectedMonthYear.label}
                                    </div>
                                )
                            }

                            <Calendar
                                fullscreen={false}
                                mode="month"
                                value={dayjs().year(selectedMonthYear.year).month(selectedMonthYear.month)}
                                dateFullCellRender={dateCellRender}
                                headerRender={() => null}
                            />
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DateRangeModal;
