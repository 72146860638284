import React from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import {Button, Descriptions, DescriptionsProps, Typography} from "antd";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/user.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "../../../../../assets/icons/phone.svg";
import {Contact} from "../../../../../models/Contact";
import {RequestActivationStatus} from "../../../../../models/RequestActivationStatus";

interface BcLeftSidebarProps {
    requestActivation: RequestActivation;
    contacts: Contact[];
}

function BcLeftSidebar({ requestActivation, contacts }: BcLeftSidebarProps) {
    const themeConfig = useThemeLayoutConfig();

    const getFirstAvailableContact = () => {
        return contacts.length > 0 ? contacts[0] : null;
    }

    const getContactEmail = () => {
        return getFirstAvailableContact() ? getFirstAvailableContact()?.email : '----';
    }

    const getContactName = () => {
        return getFirstAvailableContact() ? `${getFirstAvailableContact()?.name} ${getFirstAvailableContact()?.lastName}` : '----';
    }

    const getContactPhoneNumber = () => {
        return getFirstAvailableContact() ? `${getFirstAvailableContact()?.dialCode || ''} ${getFirstAvailableContact()?.phoneNumber || ''}` : '----';
    }

    const getItems = (): DescriptionsProps['items'] => {
        return [
            {
                label: 'Compañía',
                children: <Typography style={{ color: themeConfig.token?.colorPrimary, fontWeight: 550 }}>{ requestActivation.company?.name }</Typography>,
                span: 3
            },
            {
                label: 'Tipo de activación',
                children: requestActivation.requestActivationType?.name,
                span: 3
            },
            {
                label: 'Estado',
                children: (
                    <span className="bc-left-sidebar-status" style={{ whiteSpace: 'nowrap' }}>
                        <span
                            style={{
                                width: 6,
                                height: 6,
                                borderRadius: '50%',
                                backgroundColor: requestActivation.requestActivationStatus?.alternativeColor,
                                display: 'inline-block',
                                marginRight: 8,
                            }}
                        />

                        <span>{requestActivation.requestActivationStatus?.alternativeName}</span>
                </span>
                ),
                span: 3
            },
            {
                label: 'Contacto',
                children: (
                    <div>
                        <span className="bc-request-activation-details-left-sidebar">
                            <UserIcon style={{ fill: themeConfig.token?.colorPrimary }} /> <Typography>{ getContactName() }</Typography>
                        </span>

                        <span className="bc-request-activation-details-left-sidebar">
                            <MailIcon style={{ fill: themeConfig.token?.colorPrimary }} /> <Typography>{ getContactEmail() }</Typography>
                        </span>

                        <span className="bc-request-activation-details-left-sidebar">
                            <PhoneIcon style={{ fill: themeConfig.token?.colorPrimary }} /> <Typography>{ getContactPhoneNumber() }</Typography>
                        </span>
                    </div>
                ),
                span: 3
            }
        ];
    }

    return (
        <div style={{ width: '100%' }}>
            <Descriptions
                items={getItems()}
                size="default"
                style={{ width: '100%' }}
                layout="vertical"
            />
        </div>
    );
}

export default BcLeftSidebar;
