import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import setUpInterceptor from './utils/AxiosInterceptos';
import { AuthService } from './services/AuthService';
import LoadingScreen from './components/LoadingScreen/LoadingScreen';
import AuthAction from './redux/actions/authActions/Actions';
import AppRoutes from './routes/routes';
import { User } from './models/User';
import {ItemInterface} from "./models/ItemInterface";
import {useThemeLayoutConfig} from "./config/ThemeDashboardLayoutConfig";
import {CustomCssConfig} from "./config/CustomCssConfig";
import dayjs from 'dayjs';
import 'dayjs/locale/es';

import './App.scss';

dayjs.locale('es');

function App() {
  const themeConfig = useThemeLayoutConfig();
  const store = useStore();
  const selectedCompany: ItemInterface = useSelector((state: any) => state.selectedCompany);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  /* Iniciando los interceptores de axios para el token */
  setUpInterceptor(store);

  useEffect(() => {
    init();
  }, [dispatch, store]);

  useEffect(() => {
    document.documentElement.style.backgroundColor = loading ? 'white' : 'white';
  }, [loading]);

  const init = async () => {
    CustomCssConfig.loadInitialCss(themeConfig);
    await verifySession();
    setLoading(false);
  };

  /* Rehidratación del usuario logeado */
  const verifySession = async (): Promise<void> => {
    const verifyUserSessionResponse = await AuthService.verifySession();

    if (verifyUserSessionResponse.success) {
      const currentUser = verifyUserSessionResponse.data as User;
      dispatch(AuthAction.signIn(currentUser));

    } else {
      dispatch(AuthAction.signOut({}));
    }
  };

  return (
      <>
        {loading ? <LoadingScreen /> : <AppRoutes />}
      </>
  );
}

export default App;
