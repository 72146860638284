import React, {useEffect, useState} from 'react';
import {
    ExclamationCircleOutlined,
    EyeOutlined,
    FilterOutlined,
    FormOutlined,
    MoreOutlined, PlusOutlined,
} from "@ant-design/icons";
import {Button, Card, Col, Dropdown, Input, MenuProps, message, Modal, Row, Table, TableProps, Tag} from "antd";
import {PAGE_SIZE} from "../../config/Constants";
import {RequestActivation} from "../../models/RequestActivation";
import {TableParams} from "../../models/TableParams";
import {RequestActivationService} from "../../services/RequestActivationService";
import {SearchProps} from "antd/es/input";
import Layout from "../../components/Layout/Layout";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import {RequestActivationStatus} from "../../models/RequestActivationStatus";
import AdminRequestActivationDetailModal
    from "./components/AdminRequestActivationDetailModal/AdminRequestActivationDetailModal";
import RefusedStatusModal from "./components/RefusedStatusModal";
import RefusedOcModal from "./components/RefusedOcModal";
import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import {useSearchParams} from "react-router-dom";

import './Requests.scss';
import {RequestActivationHelper} from "../../utils/RequestActivationHelper";

const { Search } = Input;

function Requests() {
    const [modal, contextModalHolder] = Modal.useModal();
    const [searchParams, setSearchParams] = useSearchParams();
    const rqParam = searchParams.get('rq');
    const [messageApi, contextHolder] = message.useMessage();
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [dataSource, setDataSource] = useState<RequestActivation[]>([]);
    const [requestActivationStatuses, setRequestActivationStatuses] = useState<RequestActivationStatus[]>([]);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: PAGE_SIZE,
        },
        sortField: 'createdAt',
        sortOrder: 'descend'
    });

    const [term, setTerm] = useState(rqParam ? rqParam : '');
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<RequestActivation | undefined>(undefined);

    const [isRefusedStatusModalOpen, setIsRefusedStatusModalOpen] = useState(false);
    const [isRefusedOcModalOpen, setIsRefusedOcModalOpen] = useState(false);
    const [detailCallback, setDetailCallback] = useState< () =>void | undefined>();

    useEffect(() => {
        fetchData();
    }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.sortField, tableParams.sortOrder]);

    useEffect(() => {
        fetchData(1);
    }, [term]);

    useEffect(() => {
        init();

    }, []);

    const init = async () => {
        searchParams.delete('rq');
        setSearchParams(searchParams);

        const [
            requestActivationStatusResponse,
        ] = await Promise.all([
            RequestActivationService.getAllRequestActivationStatuses(),
            fetchData()
        ]);

        if(requestActivationStatusResponse.success) {
            setRequestActivationStatuses(requestActivationStatusResponse.data);

            if(rqParam) {
                const row = await RequestActivationService.findById(rqParam);

                if(row.success) {
                    showDetailModalOpen(row.data);
                }else {
                    console.error(`Error rqParam: ${rqParam}`, row.data);
                }
            }

            setLoading(false);
        }else {
            if(!requestActivationStatusResponse.success) {
                const error = requestActivationStatusResponse.data;
                messageApi.error(error.message as string || 'Hubo un error al intentar obtener los estados de las activaciones, por favor inténtalo nuevamente.', 3.5);
            }
        }
    }

    const fetchData = async (currentPage?: number) => {
        setTableLoading(true);


        const datasourceResponse = await RequestActivationService.getAllByCurrentUserCountry({
            page: currentPage || (tableParams.pagination?.current || 1),
            pageSize: tableParams.pagination?.pageSize || PAGE_SIZE,
            sortField: tableParams.sortField,
            sortOrder: tableParams.sortOrder,
            term: term || undefined
        });

        if(datasourceResponse.success) {
            const data = datasourceResponse.data;
            setDataSource(data.data);
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: parseInt(`${data.totalRowsFiltered}`)
                }
            })
        }else {
            const error = datasourceResponse.data;
            messageApi.error(error.message as string || 'Hubo un error al intentar obtener los datos de la grilla, por favor inténtalo nuevamente.', 3.5);
        }

        setTableLoading(false);
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setTerm(value);
    };

    const handleTableChange: TableProps<RequestActivation>['onChange'] = (pagination, filters, sorter) => {
        const sorterLocal = sorter as any;

        setTableParams({
            pagination,
            filters,
            sortOrder: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.order : undefined,
            sortField: (sorterLocal.order && sorterLocal.columnKey) ? sorterLocal.columnKey : undefined
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setDataSource([]);
        }
    };

    const getDropdownMenu = (row: RequestActivation): MenuProps['items'] => {
        return [
            {
                key: '1',
                label: <span><EyeOutlined style={{ marginRight: '8px' }}/> Ver solicitud</span>,
                onClick: () => { showDetailModalOpen(row); }
            },
        ];
    }

    const showDetailModalOpen = (row: RequestActivation) => {
        setSelectedRow(row);
        setIsDetailModalOpen(true);
    }

    const closeDetailModalOpen = () => {
        setIsDetailModalOpen(false);
    }

    const showRefusedStatusModalOpen = (row: RequestActivation, callback?: () => void) => {
        setSelectedRow(row);
        setIsRefusedStatusModalOpen(true);
        if(callback) {
            setDetailCallback(() => callback);
        }
    }

    const closeRefusedStatusModalOpen = () => {
        setIsRefusedStatusModalOpen(false);
    }

    const showRefusedOcModalOpen = (row: RequestActivation, callback?: () => void) => {
        setSelectedRow(row);
        setIsRefusedOcModalOpen(true);
        if(callback) {
            setDetailCallback(() => callback);
        }
    }

    const closeRefusedOcModalOpen = () => {
        setIsRefusedOcModalOpen(false);
    }

    return (
        <Layout breadcrumb={[
            { title: <span><FormOutlined/> Solicitudes</span> }
        ]}
        >
            { contextModalHolder }
            { contextHolder }

            <Card
                loading={loading}
            >
                <div className="filter-container">
                    <Row gutter={[24, 16]}>
                        <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                            <label className="filter-search-container">Filtrar por: <Search defaultValue={term || ''} placeholder="N° de solicitud, marca o cliente" onSearch={onSearch} disabled={tableLoading} allowClear/></label>
                        </Col>
                        <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                            <Button type="primary" disabled={tableLoading}><FilterOutlined /></Button>
                        </Col>
                    </Row>
                </div>
            </Card>

            <Card
                style={{ marginTop: '18px' }}
                loading={loading}
            >
                <Table
                    <RequestActivation>
                    dataSource={dataSource}
                    bordered
                    loading={tableLoading}
                    pagination={tableParams.pagination}
                    size="small"
                    scroll={{ y: `CALC(100VH - 390px)`, x: 768 }}
                    rowKey={(record) => { return record.id }}
                    onChange={handleTableChange}
                >
                    <Column width={120} fixed="left" align="center" title="N° Solicitud" dataIndex="id" key="id"/>

                    <Column align="center" title="Fecha de solicitud" key="createdAt" render={(row: RequestActivation) => (
                        <span title={dayjs(row.createdAt).format('DD/MM/YYYY hh:mm A')}>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>
                    )} sorter={true} defaultSortOrder="descend" width={150}/>

                    <Column width={240} ellipsis title="Cliente" key="company" render={(row: RequestActivation) => (
                        <>{row.company?.name}</>
                    )}/>

                    <Column width={240} ellipsis title="Marca" key="brand" render={(row: RequestActivation) => (
                        <>{row.brand?.name}</>
                    )}/>

                    <Column width={240} ellipsis title="Fase" key="status" render={(row: RequestActivation) => (<StatusIndicator text={row.requestActivationStatus!.privateName} color={row.requestActivationStatus!.privateColor}/>)} />

                    <Column
                        width={160}
                        align="center"
                        title="Tarea pendiente"
                        key="attentionRequired"
                        render={ (row: RequestActivation) => RequestActivationHelper.getAttentionRequiredForClients(row.requestActivationStatusId) }
                    />

                    <Column width={60} fixed="right" align="center" title="" key="actions" render={(row) => (
                        <Dropdown menu={ { items: getDropdownMenu(row) } } placement="bottomLeft" trigger={['click']}>
                            <Button size="small"><MoreOutlined /></Button>
                        </Dropdown>
                    )} />
                </Table>
            </Card>

            {/* Modals */}
            {
                selectedRow && (
                    <AdminRequestActivationDetailModal
                        isOpen={isDetailModalOpen}
                        handleModalCancel={closeDetailModalOpen}
                        selectedRequestActivation={selectedRow}
                        requestActivationStatuses={requestActivationStatuses}
                        reloadGrid={fetchData}
                        showRefusedStatusModalOpen={showRefusedStatusModalOpen}
                        showRefusedOcModalOpen={showRefusedOcModalOpen}
                    />
                )
            }
            {
                selectedRow && (
                    <RefusedStatusModal
                        isOpen={isRefusedStatusModalOpen}
                        handleModalCancel={closeRefusedStatusModalOpen}
                        selectedRequestActivation={selectedRow}
                        fetchTable={fetchData}
                        detailCallback={detailCallback}
                    />
                )
            }

            {
                selectedRow && (
                    <RefusedOcModal
                        isOpen={isRefusedOcModalOpen}
                        handleModalCancel={closeRefusedOcModalOpen}
                        selectedRequestActivation={selectedRow}
                        fetchTable={fetchData}
                        detailCallback={detailCallback}
                    />
                )
            }
        </Layout>
    );
}

export default Requests;
