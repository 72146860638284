import React from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {Collapse, Typography} from "antd";
import {useThemeLayoutConfig} from "../../../../../config/ThemeDashboardLayoutConfig";
import {
    CalendarOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {Schedule} from "../../../../../models/Schedule";;

interface GeneralDetailTabProps {
    requestActivation: RequestActivation
}

function BcGeneralDetailTab({ requestActivation }: GeneralDetailTabProps) {
    const themeConfig = useThemeLayoutConfig();

    const getAllCollaborators = () => {
        return requestActivation.activations!.reduce((accumulator, currentActivation) => {
            return accumulator + currentActivation.numberOfCollaborators;
        }, 0);
    };

    const getFormattedDates = () => {
        const activations = requestActivation.activations!;

        let allDates: string[] = [];

        activations.forEach((activation) => {
            allDates = allDates.concat(activation.dates);
        });

        // @ts-ignore
        const dayJsDates = [...new Set(allDates)].map((date) => {
            return dayjs(date, 'YYYY-MM-DD');
        });

        const minDate = dayJsDates.reduce((a, b) => a.isBefore(b) ? a : b);
        const maxDate = dayJsDates.reduce((a, b) => a.isAfter(b) ? a : b);

        return `${minDate.format('DD/MM/YYYY')} - ${maxDate.format('DD/MM/YYYY')}`
    }

    const getUqSchedules = () => {
        const activations = requestActivation.activations!;
        const schedules: Schedule[] = [];

        activations.forEach((activation) => {
            const check = schedules.find((schedule) => {
                return schedule.id === activation.scheduleId;
            });

            if(!check) {
                schedules.push(activation.availableSchedule!);
            }
        });

        return schedules;
    }

    return (
        <div className="bc-request-activation-details-general-tab">
            <div className="bc-general-detail-class-container">
                <div className="bc-general-detail-class-children" style={{ borderLeft: `4px solid ${themeConfig.components!.Menu!.itemActiveBg}` }}>
                    <Typography><span style={{ fontWeight: 550 }}>Marca: </span>{ requestActivation.brand!.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Servicio: </span>{ requestActivation.activationService?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Objetivos de la activación: </span>{ requestActivation.activationGoal?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Dinámica especial: </span>{ requestActivation.requiresSpecialDynamic ? 'Si' : 'No' }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Tipo de activación: </span>{ requestActivation.requestActivationType?.name } - { requestActivation.requestActivationSubType?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Materiales promocionales: </span>{ requestActivation.promotionalMaterial?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Equipamiento extra: </span>{ requestActivation.equipmentAddon?.name }</Typography>
                    <Typography><span style={{ fontWeight: 550 }}>Captadores: </span>{ getAllCollaborators() }</Typography>
                    <Typography><CalendarOutlined style={{ marginRight: '5px' }} />{getFormattedDates()}</Typography>
                    {
                        getUqSchedules().map((schedule) => (
                            <Typography key={schedule.id}><ClockCircleOutlined style={{ marginRight: '5px' }}/> {schedule.label}</Typography>
                        ))
                    }
                </div>
                <div className="bc-general-detail-class-children" style={{ borderLeft: `4px solid ${themeConfig.components!.Menu!.itemActiveBg}` }}>
                    <Typography><span style={{ fontWeight: 550 }}>Vestimenta estándar</span></Typography>
                    <ul>
                        <li>Polera Blanca</li>
                        <li>Pantalón Negro</li>
                        <li>Gorra</li>
                        <li>Credencial</li>
                    </ul>
                </div>
            </div>

            <Collapse items={[
                            {
                                key: '1',
                                label: <span>Observaciones adicionales</span>,
                                children: (
                                    <div style={{ width: '100%'}}>
                                        { requestActivation.additionalInfo1 || '- Sin Observaciones -' }
                                    </div>
                                )
                            }
                        ]}
                      defaultActiveKey={['1']}
                      style={{ marginTop: '15px' }}
            />
        </div>
    );
}

export default BcGeneralDetailTab;
