import React, {useState} from 'react';
import {RequestActivation} from "../../../../../models/RequestActivation";
import {Col, Row, Table} from "antd";
import {Activation} from "../../../../../models/Activation";
import Column from "antd/es/table/Column";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/es/input";
import {FunctionsHelper} from "../../../../../utils/FunctionsHelper";
import DateRangeModal from "../../../../../components/DateRangeModal/DateRangeModal";

interface BcScheduleTabProps {
    requestActivation: RequestActivation
}

function BcScheduleTab({ requestActivation }: BcScheduleTabProps) {
    const [filteredDataSource, setFilteredDataSource] = useState<Activation[]>([...(requestActivation.activations || [])]);
    const [isDateRangeModalOpen, setIsDateRangeModalOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState<string[]>([]);


    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        const activations = [...(requestActivation.activations || [])];
        const term = FunctionsHelper.normalizeText(value.trim());

        if(term.length === 0) {
            setFilteredDataSource(activations);
        }else {
            const localFilteredDataSource = activations.filter((record) => {
                return FunctionsHelper.normalizeText(record.store?.code).includes(term) || FunctionsHelper.normalizeText(record.store?.name).includes(term)
            });
            setFilteredDataSource(localFilteredDataSource);
        }
    };

    return (
        <div className="bc-request-activation-details-schedule-tab">
            <div className="filter-container">
                <Row gutter={[24, 16]}>
                    <Col xs={24} md={24} lg={12} xl={10}  xxl={8}>
                        <label className="filter-search-container">Filtrar por: <Search placeholder="Punto de venta o código" onSearch={onSearch} allowClear/></label>
                    </Col>
                    <Col xs={24} md={24} lg={12} xl={14}  xxl={16} className="filter-buttons-container">
                        {/*<Button type="primary"><FilterOutlined /></Button>*/}
                    </Col>
                </Row>
            </div>

            <Table
                <Activation>
                dataSource={filteredDataSource}
                pagination={false}
                size="small"
                scroll={{ x: 768 }}
                style={{ marginTop: '15px' }}
                rowKey={(record) => { return record.id }}
            >
                <Column align="center" fixed width={90} title="Código" key="code" render={(row: Activation) => (
                    <>{ row.store!.code }</>
                )}/>

                <Column width={320} title="PDV" key="store" ellipsis render={(row: Activation) => (
                    <>{ row.store!.name }</>
                )}/>

                <Column width={320} title="Dirección PDV" ellipsis key="address" render={(row: Activation) => (
                    <>{ row.store!.address }</>
                )}/>

                <Column width={180} title="Fechas" key="dates" render={(row: Activation) => (
                    <a
                        onClick={() => {
                            setSelectedDates(row.dates);
                            setIsDateRangeModalOpen(true);
                        }}
                        title="Ver las fechas seleccionadas"
                    >
                        { `${dayjs(row.dates[0], 'YYYY-MM-DD').format('DD/MM/YYYY')} - ${dayjs(row.dates[row.dates.length - 1], 'YYYY-MM-DD').format('DD/MM/YYYY')}` }
                    </a>
                )}/>

                <Column width={60} align="center" title="Días" key="days" render={(row: Activation) => (
                    <>{ row.dates.length }</>
                )}/>

                <Column width={320} title="Horarios" key="schedules" render={(row: Activation) => (
                    <>{ row.availableSchedule?.label }</>
                )}/>

                <Column width={110} align="center" title="Colaboradores" key="numberOfCollaborators" render={(row: Activation) => (
                    <>{ row.numberOfCollaborators }</>
                )}/>
            </Table>

            <DateRangeModal
                open={isDateRangeModalOpen}
                onClose={() => setIsDateRangeModalOpen(false)}
                dates={selectedDates}
            />
        </div>
    );
}

export default BcScheduleTab;
